import React, { useState } from "react";
import {
	ModalComponentWrapper,
  OtionEditCLH,
  ActionButton,
} from "./styled";
import { Col, Row, Form, Input, Radio } from "antd";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { FormProvider, useForm } from "react-hook-form";
import { NormalBtn, FailureBtn } from "Components/Button";
import { useDispatch } from "react-redux";
import actions from "Store/Actions";
import { Notification } from "Components";

i18next.loadNamespaces(['student']);
const { editClh } = actions;

const ModalEditCourseClh = ({ onOk, onCancel, visibleModalEditClh, dataRow, listMonthCompare , listScheduleCourse }) => {
  const dispatch = useDispatch();
  const [t] = useTranslation('student');
  const [numberCLh, setNumberCLh] = useState(3)
  const [optionEditCLH, setOptionEditCLH] = useState("one");

  const hanldeChangeTime = (e) => {
    setNumberCLh(Number(+e.target.value))
  }

  const form = useForm({
    defaultValues: {
      'CL / h': numberCLh
    }
  });
  const handleKeep = () => {
    let newData
    if (optionEditCLH === 'one') {
      newData = dataRow
      newData['CL / h'] = numberCLh
    }
    else {
      newData = listScheduleCourse.map(item => {
        if (dataRow.科目 === item.科目) {
          return { ...item, 'CL / h':  numberCLh};
        }
        return item
      });;
    }
    dispatch(editClh({ newData , optionEditCLH }, (action, res) => {}));
    onCancel();
    Notification.success(t("message.edit_clh_success"));
  }
  const onChange = (e) => {
    setOptionEditCLH(e.target.value);
  };
  const handleKeyDown = (e) => {
    const keyCode = e.keyCode || e.which;
    if (!/^\d$/.test(e.key) && keyCode !== 8) {
      e.preventDefault();
    }
  }
  return (
    <ModalComponentWrapper
      open={visibleModalEditClh}
      title={t('edit_clh')}
			onOk={onOk}
			onCancel={onCancel}
			centered
		>
			<FormProvider {...form}>
        <form id="confirm-infomation-form" method="post" autoComplete="off" defaultValues>
            <OtionEditCLH>
              <Radio.Group
                className="option__edit"
                onChange={onChange}
                value={optionEditCLH}
              >
              <Radio value={"one"}>{t('option_edit_one')}</Radio>
                <Radio value={"all"}>{t('option_edit_all')}</Radio>
              </Radio.Group>
            </OtionEditCLH>
           <Row gutter={[32]}>
            <Col span={24}>
              <Form.Item label={t('CL/H')}>
                <Form.Item noStyle>
                  <Input
                    name="CL / h"
                    type="number"
                    size="large"
                    min={1}
                    value={numberCLh}
                    defaultValue={numberCLh}
                    onChange={hanldeChangeTime}
                    title=""
                    onKeyDown={handleKeyDown}
                  />
                </Form.Item>
              </Form.Item>
            </Col>
          </Row>
          <ActionButton>
            <NormalBtn onClick={onCancel}>{t("cancel")} </NormalBtn>
            <FailureBtn
              disabled={numberCLh <= 0}
              onClick={handleKeep}
            >
              {t("confirm_edit")}
            </FailureBtn>
          </ActionButton>
				</form>
			</FormProvider>

		</ModalComponentWrapper>
	);
}

export default ModalEditCourseClh