import React from "react";
import {
  ActionButton,
  ModalComponentWrapper,
  ItemSubject,
  Subject,
  Icon,
  ButtonCancel,
  ButtonUpdate,
} from "./styled";
import { useTranslation } from "react-i18next";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useState } from "react";
import { useEffect } from "react";
import { SUBJECT_NAME } from "Utils/data-default";
import { MenuOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import actions from "Store/Actions";
const { updateMoveSubject } = actions;

const ModalEditSubject = ({ onOk, onCancel, visibleModal, dataSource }) => {
  const [t] = useTranslation('student');
  const [subjectList, setSubjectList] = useState([]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getListSubject = [...new Set(dataSource.map(item => item.科目))]
    setSubjectList(getListSubject)
  },[dataSource])

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(subjectList);
    setSubjectList(items);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

  };

  const handleUpdate = () => {
    const newData = dataSource.sort((a, b) => {
        return subjectList.indexOf(a.科目) - subjectList.indexOf(b.科目)
      })
    dispatch(
      updateMoveSubject({
        data: newData
      })
    );
    onCancel()
  }
  return (
      <ModalComponentWrapper
        open={visibleModal}
        onOk={onOk}
        onCancel={onCancel}
        // centered
        title='科目順番変更'
        className='modal__edit_subject'
        mask={false}
        maskClosable={false}
        getContainer="#id_modal__edit_subject"
      >
        <DragDropContext
          onDragStart={() => {
            setLoading(true)
          }}
          onDragEnd={(result) => {
            setLoading(false);
            handleDragEnd(result);
          }}
        >
        <Droppable droppableId="listSubject">
          {(provided) => (
            <ul className="list-subject" {...provided.droppableProps} ref={provided.innerRef}>
              {subjectList.map((item, index) => (
                <Draggable key={item} draggableId={item} index={index}>
                  {(provided) => (
                    <li
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      ref={provided.innerRef}
                    >
                      <ItemSubject>
                        <Icon>
                          <MenuOutlined />
                        </Icon>
                        <Subject className={`bg-${SUBJECT_NAME?.[item]}`} >{item}</Subject>
                      </ItemSubject>
                    </li>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
        <ActionButton>
          <ButtonUpdate disabled={loading}  onClick={handleUpdate}>{t('update')}</ButtonUpdate>
          <ButtonCancel  onClick={onCancel}>{t('cancel')}</ButtonCancel>
        </ActionButton>
      </ModalComponentWrapper>
  );
};

export default ModalEditSubject;
