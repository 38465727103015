import ModalComponent from "Components/Modal";
import styled from "styled-components";
import { Col } from "antd";
import { COLORS_SUBJECT } from "Utils/data-default";

export const ModalComponentWrapper = styled(ModalComponent)`
  &.modal__edit_subject{
    width: 500px !important;
    top: 0;
    .ant-modal-content{
      padding: 0;
    }
    .ant-modal-body {
    }
    .select__version {
      height: 40px;
      width: 100%;
      .ant-select-selector {
        height: 100%;
        display: flex;
        align-items: center;
        background-color: #4994FF;
        color: white;
      }
      .ant-select-selection-placeholder{
        color: white;
        font-size: 14px;
      }
      .ant-select-arrow{
        color: white;
      }
      .ant-select-clear{
        display: flex;
        background-color: #4994FF;
      }
    }
    .ant-modal-close {
        background-color: #f7f0f0 !important;
        top: 10px;
        right: 10px;
        .ant-modal-close-x{
          color: black;
        }
    }
    .list-subject{
        padding: 0;
      }

  }
  
`;
export const TitleUpdate = styled.div`
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #000;
`
export const HeaderModal = styled(Col)`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding-top: 16px;
  padding-bottom: 10px;
  border-bottom: 1px solid #d9d9d9;

  @media only screen and (max-width: 768px) {
    margin-bottom: 25px;
  }

  .header_modal {
    display: grid;
  }

  .info_title {
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 10px;
  }

  .info_data {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
  }
`;
export const ActionButton = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  button {
    height: 40px;
    min-width: 100px;
    letter-spacing: 1px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 2rem;
    font-family: "Public Sans";
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: none;
    margin: 0 10px;
    &:hover{
      opacity: 0.7;
    }
    &:disabled{
      background-color: #878484;
      color: #000000;
    }
    @media only screen and (max-width: 768px) {
      font-size: 10px;
      height: 30px;
      line-height: 10px;
    }
  }
`;
export const ButtonUpdate = styled.button`
  background-color: #4994ff;
  color: #ffffff;
`;
export const ButtonCancel = styled.button`
  background-color: #e7e3e8;
  color: #000;
`;
export const ItemSubject = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #c9c9c9;
  border-collapse:collapse;
  /* width: fit-content; */
  width: 100%;
`
export const Icon = styled.div`
  display: flex;
  padding: 10px;
`
export const Subject = styled.div`
  padding: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  &.bg-english{
      background-color: ${COLORS_SUBJECT.english};
  }
  &.bg-national_language{
    background-color: ${COLORS_SUBJECT.national_language};
  }
  &.bg-society{
    background-color: ${COLORS_SUBJECT.society};
  }
  &.bg-math{
    background-color: ${COLORS_SUBJECT.math};
  }
  &.bg-science{
    background-color: ${COLORS_SUBJECT.science};
  }
  &.bg-eiken_specialized_course{
    background-color: ${COLORS_SUBJECT.eiken_specialized_course};
  }
  &.bg-essay{
    background-color: ${COLORS_SUBJECT.essay};
  }
  &.bg-information{
    background-color: ${COLORS_SUBJECT.information};
  }
`