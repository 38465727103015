import React, { useState } from "react";
import {
	ModalComponentWrapper,
  HeaderModal,
  GroupButton,
  WrapperLabel,
} from "./styled";
import { Col, Row, Form, Select, DatePicker, Input } from "antd";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { FormProvider, useForm } from "react-hook-form";
import { LIST_OPTION_EDIT, LIST_OPTION_LEARN, month, textNoData, week } from "Utils/data-default"
import { NormalBtn, FailureBtn } from "Components/Button";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash"
import actions from "Store/Actions";
import { Notification } from "Components";
import dayjs from "dayjs";
import ModalAlert from "../ModalAlert";
import { useMemo } from "react";
import { useEffect } from "react";

i18next.loadNamespaces(['student']);
const { editCourse, editAllCourse } = actions;


const ModalEditCourse = ({ onOk, onCancel, visibleModalEdit, dataRow, listMonthCompare, weekSelect, listScheduleCourse }) => {
  const {
    currentStudentInformation,
  } = useSelector((state) => state.student);
  const dispatch = useDispatch();
  const [t] = useTranslation('student');
  const [timeHours, setTimeHours] = useState(1)
  const [optionLearn, setOptionLearn] = useState(1)
  const [optionEdit, setOptionEdit] = useState('only-week')
  const [monthStart, setMonthStart] = useState(month[0]?.value)
  const [weekStart, setWeekStart] = useState(week[0]?.value)
  const [yearStart, setYearStart] = useState(currentStudentInformation?.startTime && currentStudentInformation?.startTime.split('/')[0])
  const [showModalAlert, setShowModalAlert] = useState(false)
  const [disableButton, setDisableButton] = useState(false)
  let myNewArray = [];
  _.forEach(dataRow, (value, key) => {
    if (dataRow["映・添"] === "添削") {
      if (key !== "番号" && key !== "TIME_UNIT" && key !== "key" && key !== "newCourse" && key !== "答案添削" && key !== "総講数" && key !== "CL / h" && key !== "必要時限") {
        myNewArray.push({
          key: key,
          title: value,
        });
      }
    }
    else {
      if (key !== "番号" && key !== "TIME_UNIT" && key !== "key" && key !== "newCourse") {
        myNewArray.push({
          key: key,
          title: value,
        });
      }
    }
  });

  const valueTimeLimit = useMemo(() => {
    const keysTimeUnit = Object.keys(dataRow['TIME_UNIT'])
    const valueTimeUnit = Object.values(dataRow['TIME_UNIT']).map(item => item.unit)
    let timeLimit = dataRow["必要時限"]
    const indexSelect = keysTimeUnit.indexOf(weekSelect)
    for (let i = 0; i < indexSelect; i++){
      timeLimit -= valueTimeUnit[i]
    }
  return timeLimit
  }, [dataRow, weekSelect])

  const renderMaxTimeHours = useMemo(() => {
    if (optionEdit === 'all-course-subject' || optionEdit === 'all-course') {
      return undefined
    }
    else if (optionEdit === 'only-week') {
      return valueTimeLimit
    }
    else if (dataRow) {
      return dataRow["必要時限"]
    }
    else 
      return 10
  }, [dataRow, optionEdit, valueTimeLimit])

  const hanldeChangeTime = (e) => {
    const valueInput = Number(+e.target.value)
    if (Number(valueInput) > +dataRow["必要時限"] && (optionEdit === 'all-week')) {
      setTimeHours(dataRow["必要時限"])
    }
    else if (Number(valueInput) > +valueTimeLimit && (optionEdit === 'only-week')) {
      setTimeHours(valueTimeLimit)
    }
    else {
      setTimeHours(Number(+valueInput))
    }
  }

  const hanldeChangeOptionLearn = (e) => {
    setOptionLearn(e)
  }
  const hanldeChangeOptionEdit = (e) => {
    if (e === 'only-week') {
      const getTimeUnit = dataRow['TIME_UNIT']
      if ( Object.keys(getTimeUnit).length === 0) {
        setDisableButton(true)
      }
    }
    else if (e === 'all-week') {
      setDisableButton(false)
    }
    else if (e === 'all-course-subject' || e === 'all-course' || e === 'detele-all') {
      const getListTimeUnit = listScheduleCourse.map(item => 
        item.TIME_UNIT 
      )
      const checkListTimeUnitEmpty = getListTimeUnit.every((value, index) => Object.keys(value).length === 0)
      if ( checkListTimeUnitEmpty) {
        setDisableButton(true)
      }
      else setDisableButton(false)
    }
    setOptionEdit(e)
  }
  useEffect(() => {
    dataRow?.["映・添"] === "映像" && hanldeChangeOptionEdit('only-week')
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  const handleMonth = (e) => {
    setMonthStart(e)
  }

  const handleWeek = (e) => {
    setWeekStart(e)
  }

  const handleYear = (date, dateString) => {
    setYearStart(dateString)
  }

  const form = useForm({
    // resolver: yupResolver(validationSchema),
    defaultValues: {
      timeLearn: timeHours
    }
  });

  const handleKeep = () => {
    const cloneListSchedule = listScheduleCourse.slice()
    if (optionEdit==='detele-all') {
      setShowModalAlert(true)
      return
    }
    if (optionEdit === 'all-course-subject') {
      cloneListSchedule.map(item => {
        if (dataRow.科目 === item.科目 && item["映・添"] ==="映像" && Object.keys(item['TIME_UNIT']).length) {
          let newTimeUnitAll  = {}
          const { TIME_UNIT } = item
          const timeLimit = item['必要時限']
          const getKeysTimeUnit = Object.keys(TIME_UNIT)
          const lengthTimeUnit = Math.ceil(timeLimit / timeHours)
          const indexUnit = listMonthCompare.findIndex(item => item === getKeysTimeUnit[0])
          for (let i = 0; i < lengthTimeUnit; i++){
            if ((i + indexUnit) < listMonthCompare.length){
              newTimeUnitAll[listMonthCompare[i+indexUnit]] = {"unit": timeHours}  
            }
            else break
          }
          item.TIME_UNIT = newTimeUnitAll
          return item
        }
        return item
      });;
      dispatch(editAllCourse({ cloneListSchedule }, (action, res) => {}));
      onCancel();
      Notification.success(t("message.edit_course_success"));
      return
    }
    if (optionEdit === 'all-course') {
      cloneListSchedule.map((item,index) => {
        if (item["映・添"] === "映像" && Object.keys(item['TIME_UNIT']).length) {
          let newTimeUnitAll  = {}
          const { TIME_UNIT } = item
          const timeLimit = item['必要時限']
          const getKeysTimeUnit = Object.keys(TIME_UNIT)
          const lengthTimeUnit = Math.ceil(timeLimit / timeHours)
          const indexUnit = listMonthCompare.findIndex(item => item === getKeysTimeUnit[0])
          for (let i = 0; i < lengthTimeUnit; i++){
            if ((i + indexUnit) < listMonthCompare.length){
              newTimeUnitAll[listMonthCompare[i+indexUnit]] = {"unit": timeHours}  
            }
            else break
          }
          item.TIME_UNIT = newTimeUnitAll
          return item
        }
        return item
      });;
      dispatch(editAllCourse({ cloneListSchedule }, (action, res) => {}));
      onCancel();
      Notification.success(t("message.edit_course_success"));
      return
    }
    const weekToLearn = dataRow['映・添'] === '添削' ?   Number(Math.ceil((dataRow["総講数"]))) :  Number(Math.ceil((dataRow["必要時限"] / timeHours)))
    const newTimeUnit = {}
    let numberWeek = +weekStart
    let numberMonth = +monthStart
    let numberYear = +yearStart

    if (dataRow['映・添'] === '添削') {
      const dayChange = numberMonth < 10 ? `${numberWeek}_0${numberMonth}_${numberYear}` : `${numberWeek}_${numberMonth}_${numberYear}`
      const indexDayChange = listMonthCompare.findIndex(value => value === dayChange)
      if (indexDayChange < 0) {
        Notification.error(t("message.invalid_hours_or_course"));
        return
      }
      for (let i = 0; i < weekToLearn; i++){
        newTimeUnit[listMonthCompare[i*optionLearn+indexDayChange]] = {"unit": 'T'}
      }
    }
    else {
      if (optionEdit==='all-week') {
        for (let i = 0; i < weekToLearn; i++) {
          if (numberWeek <= 4) {
            numberMonth < 10 ? newTimeUnit[`${numberWeek}_0${numberMonth}_${numberYear}`] = {
              "unit": timeHours
            } : newTimeUnit[`${numberWeek}_${numberMonth}_${numberYear}`] = {
              "unit": timeHours
            }
            if (numberMonth === 12 && numberWeek === 4) {
              numberYear++
              numberMonth = 1
              numberWeek = 1
            } else if (numberWeek === 4) {
              numberMonth++
              numberWeek = 1
            } else {
              numberWeek++
            }
          }
        } 
      }
      else if (optionEdit === 'only-week') {
        const cloneDataRow = dataRow
        const valueBeforUpdate = cloneDataRow['TIME_UNIT'][`${weekSelect || Object.keys(cloneDataRow['TIME_UNIT'])[0]}`].unit
        cloneDataRow['TIME_UNIT'][`${weekSelect || Object.keys(cloneDataRow['TIME_UNIT'])[0]}`].unit = timeHours
        const keysTimeUnit = Object.keys(cloneDataRow['TIME_UNIT'])
        const valueTimeUnit = Object.values(cloneDataRow['TIME_UNIT']).map(item => item.unit)
        const allEqual = valueTimeUnit.every((value, index, array) => value === array[0]);
        const indexUnit = listMonthCompare.findIndex(item => item === keysTimeUnit[0])
        if (allEqual && valueBeforUpdate === timeHours) {
          for (let i = 0; i < weekToLearn; i++){
            newTimeUnit[listMonthCompare[i+indexUnit]] = {"unit": timeHours}
          }
        }
        else {
          const newUnit = []
          let timeLimit = +cloneDataRow['必要時限']
          valueTimeUnit.forEach((unit) => {
            if (timeLimit >= unit) {
              newUnit.push(unit)
              timeLimit = timeLimit - unit
            }
          });
          if (timeLimit > 0) {
            newUnit.push(timeLimit)
          }
          for (let i = 0; i < newUnit.length; i++){
            newTimeUnit[listMonthCompare[i+indexUnit]] = {"unit": newUnit[i]}  
          }
        }
      }
    }
    const listMonthChange = Object.keys(newTimeUnit);
    
    if (_.difference(listMonthChange, listMonthCompare).length === 0) {
      const newDataRow = dataRow
      newDataRow["TIME_UNIT"] = newTimeUnit
      dispatch(editCourse({ newDataRow }, (action, res) => {}));
      onCancel();
      Notification.success(t("message.edit_course_success"));
      setMonthStart(month[0]?.value)
      setWeekStart(week[0]?.value)
      setTimeHours(2)
    } else {
      Notification.error(t("message.invalid_hours_or_course"));
    }
  }
  const handleDeleteAllUnit = () => {
    const cloneListSchedule = listScheduleCourse.slice() 
    cloneListSchedule.map(item => {
      item.TIME_UNIT = {}
      return item
    });;
    dispatch(editAllCourse({ cloneListSchedule }, (action, res) => {}));
    onCancel();
    Notification.success(t("message.edit_course_success"));
    setShowModalAlert(false)
  }
  const handleKeyDown = (e) => {
    const keyCode = e.keyCode || e.which;
    if (!/^\d$/.test(e.key) && keyCode !== 8) {
      e.preventDefault();
    }
  }
  return (
    <>
		<ModalComponentWrapper
			open={visibleModalEdit}
			title={t("edit_course")}
			onOk={onOk}
			onCancel={onCancel}
			centered
		>
			<FormProvider {...form}>
				<form id="confirm-infomation-form" method="post" autoComplete="off" defaultValues>
        <HeaderModal>
          {myNewArray.map((it) => (
            <div key={it.key} className="header_modal">
              <label htmlFor="html" className="info_title">
                {it.key === "スターテス" ? "進捗率" : it.key}
              </label>
              <span className="info_data">
                {it.title}
              </span>
            </div>
          ))}
          </HeaderModal>
          {dataRow?.["映・添"] === "映像" &&
            <>
              <Row gutter={[32]}>
                <Col span={24}>
                  <Form.Item label={t("renewal_option_support")}>
                    <Form.Item noStyle>
                    <Select
                      className="select_type"
                        // name="universityNameNew"
                      showSearch
                      value={optionEdit}
                      placeholder={'select option edit'}
                      optionFilterProp="children"
                      filterOption={(input, option) => (option?.label ?? '').includes(input)}
                      onChange={hanldeChangeOptionEdit}
                      options={LIST_OPTION_EDIT}
                      notFoundContent={textNoData}
                    />
                    </Form.Item>
                  </Form.Item>
                </Col>
              </Row>  
            { optionEdit !== 'detele-all'  && <Row gutter={[32]}>
              <Col span={24}>
                <Form.Item label={t("number_of_hours")}>
                    <Form.Item noStyle >
                      <Input
                      key={renderMaxTimeHours}
                      name="timeLearn"
                      type="number"
                      size="large"
                      min={1}
                      max={renderMaxTimeHours}
                      value={timeHours}
                      defaultValue={timeHours}
                      onChange={hanldeChangeTime}
                      disabled={dataRow?.["映・添"] === "添削"}
                      title=""
                      onKeyDown={handleKeyDown}
                    />
                  </Form.Item>
                </Form.Item>
              </Col>
            </Row>}
            </>
          }
          {dataRow?.["映・添"] !== "映像" && <Row gutter={[32]}>
            <Col span={24}>
              <Form.Item label={t("attendance_timing")}>
                <Form.Item noStyle>
                <Select
                  className="select_type"
                  // name="universityNameNew"
                  showSearch
                  value={optionLearn}
                  placeholder={t("placeholder_attendance_timing")}
                  optionFilterProp="children"
                  filterOption={(input, option) => (option?.label ?? '').includes(input)}
                  allowClear
                  onChange={hanldeChangeOptionLearn}
                  options={LIST_OPTION_LEARN}
                  notFoundContent={textNoData}
                />
                </Form.Item>
              </Form.Item>
            </Col>
          </Row>}  
  
          {(optionEdit === 'all-week' || dataRow?.["映・添"] !== "映像" ) && <Row gutter={[32]}>
            <Col span={24} lg={8} id="dropdown-year">
              <Form.Item
                label={<WrapperLabel>{t("course_start_time")}</WrapperLabel>}
              >
                <DatePicker
                  picker="year"
                  className="select_year"
                  allowClear={false}
                  placeholder={t("schedule_method")}
                  onChange={handleYear}
                  inputReadOnly={true}
                  defaultValue={dayjs(yearStart, 'YYYY')}
                  // disabledDate={(current) => current && current.valueOf() < Date.now() && current.isBefore(dayjs(time.endTime).subtract(1, "year")}
                  disabledDate={(current) => current.isAfter(dayjs(currentStudentInformation.startTime).add(1, "year")) || current.isBefore(dayjs(currentStudentInformation.endTime).subtract(1, "year"))}
                  getPopupContainer={
                    () => document.getElementById('dropdown-year')
                  }
                  title=""
                />
              </Form.Item>
            </Col>
            <Col span={24} lg={8}>
              <Form.Item
                label={<WrapperLabel> </WrapperLabel>}
              >
                <Select
                  className="select_month"
                  showSearch
                  value={monthStart || null}
                  options={month}
                  defaultValue={month[0]?.value}
                  placeholder={t("schedule_method")}
                  onChange={e => handleMonth(e)}
                  optionFilterProp="children"
                  filterOption={(input, option) => (option?.label ?? '').includes(input)}
                />
              </Form.Item>
            </Col>
            <Col span={24} lg={8}>
              <Form.Item
                label={<WrapperLabel> </WrapperLabel>}
              >
                <Select
                  className="select_month"
                  showSearch
                  value={weekStart || null}
                  options={week}
                  defaultValue={week[0]?.value}
                  placeholder={t("schedule_method")}
                  onChange={handleWeek}
                  optionFilterProp="children"
                  filterOption={(input, option) => (option?.label ?? '').includes(input)}
                />
              </Form.Item>
            </Col>
          </Row>}
        <Row justify={"space-around"} className="footer_btn">
          <Col span={24} lg={12}>
            <NormalBtn onClick={onCancel}>{t("cancel")} </NormalBtn>
          </Col>
          <GroupButton span={24} lg={12}>
            <FailureBtn
              disabled={timeHours <= 0 || !optionLearn || disableButton}
              onClick={handleKeep}
            >
              {t("confirm_edit")}
            </FailureBtn>
          </GroupButton>
        </Row>
				</form>
			</FormProvider>
		</ModalComponentWrapper>
    <ModalAlert
        visibleModal={showModalAlert}
        onOk={handleDeleteAllUnit}
        onCancel={() => setShowModalAlert(false)}
        Title={'全ての講座時限を一括削除してもよろしいですか？'}
        titleOk={t("remove")}
        titleCancel={t("cancel")}
      />
    </>
	);
}

export default ModalEditCourse