import ModalComponent from 'Components/Modal'
import styled from 'styled-components'

export const ModalComponentWrapper= styled(ModalComponent)`
  width: 1000px !important;
  .ant-modal-body {
    .ant-row {
      margin-top: 10px;
    }
    .footer_btn {
      margin-top: 20px;
      .ant-col {
        display: flex;
        justify-content: center;
      }
      .ant-col:first-child {
        button {
          margin-left: 200px;
        }
      }
      .ant-col:last-child {
        button {
          margin-right: 200px;
        }
      }
    }
  }

  .ant-form-item {
    height: max-content;
    width: 100%;
    margin-bottom: 10px;

    .ant-select {
      min-height: 40px !important; 
      border-radius: 5px;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;

      .ant-select-selector{
        min-height: 40px;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #000000 !important;

        .ant-select-selection-search-input {
          height: 100%;
        }

        .ant-select-selection-item{
          display: flex;
          align-items: center;
        }
      }
    }

    .ant-form-item-row{
      display: flex;
      flex-direction: column;
    }

    .ant-form-item-label{
      text-align: start;
    }

    .ant-select-disabled{
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #000000 !important;
    }

    .select__menu {
      z-index: 10;
    }

    .select__control {
      border: ${({ validateStatus, theme }) =>
        validateStatus === 'error'
          ? `1px solid ${theme.error_ant} !important`
          : ''};
      box-shadow: ${({ validateStatus }) =>
        validateStatus === 'error' ? 'none' : ''};
    }

    .ant-form-item-children-icon {
      display: none;
    }

    .select__dropdown-indicator {
      color: hsl(0, 0%, 60%);
    }

    label::after{
      content: "" !important;
    }
  }

  .ant-form-item-control-input {
    .ant-input-number, .ant-picker {
      width: 100%;
      min-height: 40px !important; 
      border-radius: 5px;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;

      .ant-input-number-input-wrap {

        input {
          min-height: 40px;
        }
      }
    }
  }

`

export const WrapperLabel = styled.div`
	width: 100%;
	font-weight: 500;
	font-size: 1.3rem;
	line-height: 30px;
	color: #6D737A;
`;


export const OtionEditCLH = styled.div`
  margin: 20px 0;
  .option__edit {
    display: flex;
    label {
      :last-child{
        margin: 0 20px;
      }
      span {
        font-size: 18px;
      }
    }
  }
`;

export const ActionButton = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  button{
    margin: 0 20px;
  }
`